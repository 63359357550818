.file_remove::before {
  content: "x";
  color: #0a0262;
  @apply cursor-pointer;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner_list {
  content: "";
  box-sizing: border-box;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 1px solid #e4e4e4;
  border-top-color: #4185eb;
  animation: spinner 0.6s linear infinite;
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  @apply absolute;
  @apply top-1/2;
  @apply left-1/2;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 2px solid #e4e4e4;
  border-top-color: #4185eb;
  animation: spinner 0.6s linear infinite;
}
