.container {
  @apply flex;
  @apply items-center;
  @apply relative;
  padding-left: 35px;
  @apply cursor-pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  @apply absolute;
  @apply opacity-0;
  @apply cursor-pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  @apply absolute;
  @apply top-0;
  @apply left-0;
  height: 22px;
  width: 22px;
  border-radius: 5px;
  @apply bg-white;
  border: 1px solid #131a39;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  @apply bg-success-500;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  @apply absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 7px;
  height: 11px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
