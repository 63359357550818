.custom_radio_group {
  .custom_label_group {
    @apply relative;
    padding-left: 5px;
    &::after,
    &::before {
      @apply absolute;
      content: "";
      border-radius: 2px;
    }
    &::before {
      border: 1px solid rgba(#000, 0.12);
      width: 20px;
      height: 20px;
      left: -30px;
      @apply top-0;
    }
    &::after {
      width: 12px;
      height: 12px;
      top: 4px;
      left: -26px;
      @apply bg-success-500;
      @apply opacity-0;
      @apply invisible;
    }
  }
  input[type="radio"] {
    @apply opacity-0;
    @apply invisible;

    &:checked + .custom_label_group::after {
      @apply opacity-100;
      @apply visible;
    }
  }
}
