.svg_icon {
  svg {
    width: 100%;
    height: 100%;
  }

  &--hide-empty-container {
    @apply hidden;
  }
}
